import { graphql } from 'gatsby'
import data from '../data/moscow';
import cityTemplate from '../templates/city';

export default cityTemplate(data);

export const query = graphql`
  query {
    image: file(relativePath: { eq: "covers/moscow.jpg" }) {
      childImageSharp {
        fluid (maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
        sqip(numberOfPrimitives: 12, blur: 12, width: 256, height: 256) {
          dataURI
        }
      }
    }
  }
`;